import { getTitleEN } from "../utils"

//app selectors
export const getLanguage = (state) => {
    return state.app.lng
}

export const getWhitelist = (state) => {
    return state.app.whitelist
}

export const getBasename = (state) => {
    return state.app.basename
}

//step1 selectors
export const getTinderInterests = ({ step1Page: { tinderInterests }, app: { lng } }) => {
    return tinderInterests.map(i => getTitleEN(i, lng))
}

export const getIsStep1NextActive = (state) => {
    return state.step1Page.isNextActive
}

export const getStep1SelectedItems = (state) => {
    return state.step1Page.selectedItems
}

export const getPairsOrIds = ({ step1Page: { selectedItems, tinderInterests } }) => {
    let array = []
    selectedItems.forEach(item => {
        const [{ pair, id }] = tinderInterests.filter(el => el.id === item)
        array = [...array, pair || id]
    })
    return array

}

//step2 selectors
export const getWorkSpheres = ({ step2Page: { workSpheres }, app: { lng } }) => {
    return workSpheres.map(i => getTitleEN(i, lng))
}

export const getIsStep2NextActive = (state) => {
    return state.step2Page.isNextActive
}

export const getStep2SelectedItems = (state) => {
    return state.step2Page.selectedItems
}

//step3 selectors
export const getPassionsByCategories = ({ step3Page: { passionsByCategories }, app: { lng } }) => {
    return passionsByCategories.map(element => ({
        ...getTitleEN(element, lng),
        passions: element.passions.map(e => getTitleEN(e, lng))
    }))
}

export const getIsStep3NextActive = (state) => {
    return state.step3Page.isNextActive
}

export const getStep3SelectedItems = (state) => {
    return state.step3Page.selectedItems
}

export const getUsersCountValues = (state) => {
    return state.step3Page.usersCountValues
}

//endResults selectors
export const getEndResultsValues = (state) => {
    return state.endResultsPage.usersCount
}

export const getEndResultsInterests = (state) => {
    return getTinderInterests(state).filter(({id}) => getPairsOrIds(state).includes(id))
}

export const getEndResultsWorkSpheres = (state) => {
    return getWorkSpheres(state).filter(({id}) => getStep2SelectedItems(state).includes(id))
}

export const getEndResultsPassions = (state) => {
    let array = []
    getPassionsByCategories(state).forEach(({ passions }) => {
        array = [...array, ...passions.filter((item) => getStep3SelectedItems(state).includes(item.id))]
    });
    return array
}
