import axios from "axios";

const instance = axios.create({
    // withCredentials: true,
    // baseURL: 'https://quiz-',
    headers: {

    }
})

export const API = {

    registerUser(obj) {
        return instance
            .post(`https://quiz-registeruser-igqplyshma-uc.a.run.app/`, { ...obj })
            .then(response => response.data)
    },

    countUsersByTags(obj) {
        return instance
            .post(`https://quiz-countusersbytags-igqplyshma-uc.a.run.app/`, { ...obj })
            .then(response => response.data)
    }
}