module.exports = {
    step1: require("./step1.json"),
    step2: require("./step2.json"),
    step3: require("./step3.json"),
    pieChart: require("./pieChart.json"),
    resultForm: require("./resultForm.json"),
    endResults: require("./endResults.json"),
    footer: require("./footer.json"),
    mainPage: require("./mainPage.json"),
    app: require("./app.json"),
    warPolicy: require("./warPolicy.json"),
    communicationPrinciples: require("./communicationPrinciples.json")
}