import { API } from "../API/api";
import { getStep1SelectedItems, getStep2SelectedItems, getStep3SelectedItems } from "./selectors";

const initialState = {}

const resultFormReducer = (state = initialState, action) => {
    switch (action.type) {

        default:
            return state
    }

}

export default resultFormReducer;

//Action creators

//Thunk creators
export const registerUser = ({ phoneNumber, email }) => async (dispatch, getState) => {
    const state = getState()
    const registerUserForm = {
        phoneNumber,
        email,
        passionsIds: getStep3SelectedItems(state),
        interestsIds: getStep1SelectedItems(state),
        workSpheresIds: getStep2SelectedItems(state)
    }

    try {
        const data = await API.registerUser(registerUserForm)
        if (data) {
            //    console.log(data) //uid:string // id нового юзера 
        }
    } catch (error) {
        // console.log(error)
        // console.log(error.message)
    }
}
