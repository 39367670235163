import React from 'react'
import { useTranslation } from 'react-i18next'

import Paragraph from '../../components/Paragraph/Paragraph'
import SiteLayout from '../../components/SiteLayout/SiteLayout'
import policy from './mockPolicy'

//styles
import classes from "./WarPolicy.module.css"

const WarPolicy = () => {

    const [t] = useTranslation('warPolicy')

    return (
        <SiteLayout footerWidth={'752px'}>
            <div className={classes.policyContainer}>
                <div className={classes.header}>
                    {t('TITLE')}
                </div>
                <div className={classes.list}>
                    
                    {
                        policy.map(({heading, text}) => 
                        <Paragraph heading={t(heading)} text={t(text)} key={heading}/>)
                    }
                   
                    <div className={classes.textBot}>
                        {t('BOTTOM_TEXT')}
                    </div>
                </div>
            </div>
        </SiteLayout>
    )
}

export default WarPolicy