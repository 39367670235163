import React, { useEffect } from "react"
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { Provider, connect } from "react-redux";
import { useTranslation } from "react-i18next";

import EndResults from "./pages/EndResults/EndResults";
import PieChart from "./pages/PieChart/PieChart";
import ResultForm from "./pages/ResultForm/ResultForm";
import MainPage from "./pages/MainPage/MainPage";
import Step1 from "./pages/Step1/Step1";
import Step2 from "./pages/Step2/Step2";
import Step3 from "./pages/Step3/Step3";
import store from "./redux/store";
import { getBasename, getLanguage, getWhitelist } from "./redux/selectors";
import WarPolicy from "./pages/WarPolicy/WarPolicy";
import { setLanguage } from "./redux/appReducer";
// import CommunicationPrinciples from "./pages/CommunicationPrinciples/CommunicationPrinciples";
import ScrollToTop from "./components/ScrollToTop";

//styles
import classes from "./App.module.css"


const Redirect = ({ language }) => {
  const nav = useNavigate()
  useEffect(() => {
    nav("/")
  }, [language, nav])
  return null
}

const App = ({ stateLng, setLanguage, whitelist, basename }) => {

  const [, i18n] = useTranslation('app')
  const { pathname } = window.location
  const language = pathname.split("/")[1]

  useEffect(() => {
    if (language && whitelist.includes(language)) {
      i18n.changeLanguage(language).then(setLanguage(language))
    } else {
      i18n.changeLanguage(i18n.language).then(() => setLanguage(i18n.language))
    }
  }, [setLanguage, language, i18n, whitelist])


  if (!stateLng || !basename) return null

  return (
    <BrowserRouter 
      basename={basename}
    >
      <ScrollToTop/>
      <div className={classes.appContainer} >

        <Routes>
          <Route
            path="/"
            element={<MainPage />} />
          <Route
            path="step1"
            element={<Step1 />} />
          <Route
            path={"warPolicy"}
            element={<WarPolicy />} />
          {/* <Route
            path={"communicationPrinciples"}
            element={<CommunicationPrinciples />} /> */}
          <Route
            path="step2"
            element={<Step2 />} />
          <Route
            path="step3"
            element={<Step3 />} />
          <Route
            path="pieChart"
            element={<PieChart duration={10000} />} />
          <Route
            path="resultForm"
            element={<ResultForm />} />
          <Route
            path="endResults"
            element={<EndResults />} />
          <Route
            path="*"
            element={<Redirect language={stateLng} />} />
        </Routes>


      </div>
    </BrowserRouter>
  )
}

const mapStateToProps = (state) => ({
  stateLng: getLanguage(state),
  whitelist: getWhitelist(state),
  basename: getBasename(state)
})


const AppContainer = connect(mapStateToProps, { setLanguage })(App)

const MyJSApp = () => {
  return (
    <Provider store={store}>

      <AppContainer />

    </Provider>
  )
}

export default MyJSApp

