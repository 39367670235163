import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import appStoreBadge from '../../assets/images/AppStoreBadge.svg'
import googlePlayBadge from '../../assets/images/GooglePlayBadge.svg'
import SiteLayout from '../../components/SiteLayout/SiteLayout'
import Title from '../../components/Title/Title'
import ResultsItem from './ResultsItem'
import {
    getEndResultsInterests, getEndResultsPassions,
    getEndResultsValues, getEndResultsWorkSpheres
} from "../../redux/selectors"

//styles
import classes from './EndResults.module.css'


const EndResults = ({ interestsCount, workSpheresCount, passionsCount, interests, workSpheres, passions }) => {
    const [t] = useTranslation('endResults')
    const [OS, setOS] = useState('unknown')

    useEffect (()=>{
        const check = (agent) => {
            if (agent.indexOf('Android') > 0) setOS('Android')
            if (agent.indexOf('iPhone') > 0) setOS('iPhone')
        }
    check(navigator.userAgent)
    },[])

    return (
        <SiteLayout bgColor='#F2F2F2'>
            <div className={classes.bodyFlex}>

                <Title title={t('HEADER')} smile={'📈'} />

                <div className={classes.buttonFlex}>
                    <div className={classes.resultsGrid}>
                        {(interestsCount || interestsCount === 0) && <ResultsItem header={t('ITEM1_HEADER', { interestsCount })} itemsArray={interests} />}
                        {(workSpheresCount || workSpheresCount === 0) && <ResultsItem header={t('ITEM2_HEADER', { workSpheresCount })}
                            itemsArray={workSpheres} />}
                        {(passionsCount || passionsCount === 0) && <ResultsItem header={t('ITEM3_HEADER', { passionsCount })}
                            itemsArray={passions} />}

                        <div className={classes.redButtonBlurTop}>
                            <a className={classes.redButton}
                                href={OS === 'iPhone' ? 'https://apps.apple.com/us/app/young-business-club/id1547614364' : 'https://play.google.com/store/apps/details?id=com.ybc_next'} rel="noreferrer" target="_blank">
                                {t(('RED_BUTTON'))}</a>
                        </div>
                    </div>
                </div>

                <div className={classes.botFlex}>
                    <div className={classes.textContainer}>
                        <div className={classes.buttonsHeader}>{t('BUTTONS_HEADER')}</div>
                        <div className={classes.buttonsText}>{t('BUTTONS_TEXT')}</div>
                    </div>
                    <div className={classes.buttonsContainer}>
                        <a href='https://apps.apple.com/us/app/young-business-club/id1547614364' rel="noreferrer" target="_blank">
                            <img src={appStoreBadge} alt='App Store' /></a>
                        <a href='https://play.google.com/store/apps/details?id=com.ybc_next' rel="noreferrer" target="_blank">
                            <img src={googlePlayBadge} alt='Google Play' /></a>
                    </div>
                </div>

            </div>

        </SiteLayout>
    )
}

const mapStateToProps = (state) => ({
    interestsCount: getEndResultsValues(state).interestsCount,
    workSpheresCount: getEndResultsValues(state).workSpheresCount,
    passionsCount: getEndResultsValues(state).passionsCount,
    interests: getEndResultsInterests(state),
    workSpheres: getEndResultsWorkSpheres(state),
    passions: getEndResultsPassions(state)
})

export default connect(mapStateToProps, {})(EndResults)