import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollToTop = () => {
    const location = useLocation()
    const { pathname} = location


    useEffect(() => {
        window.scrollTo({top: 0})

    }, [pathname])

    return null
}

export default ScrollToTop;