import React, { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

//styles
import classes from './PieChart.module.css'

const AnimatePercent = ({ to, duration, url }) => {

    const navigate = useNavigate()

    const valueWithPercent = (value) => `${value}%`

    const [value, setValue] = useState(1);

    const animate = useCallback((toValue, delay, step = 1) => {
        const time = delay / toValue / step
        const timer = setInterval(() => {
            setValue((prev) => {
                const newValue = prev + step
                if (newValue >= to) {
                    clearInterval(timer);
                    setTimeout(() => { navigate(url) }, 100)
                }
                return newValue;
            })
        }, time)
    }, [to, navigate, url]
    )

    useEffect(() => {
        animate(to, duration)
    }, [to, animate, duration])

    return <div className={classes.pieText}>{valueWithPercent(value)}</div>
}

export default AnimatePercent