import React, { useEffect, useRef } from "react"
import { connect } from "react-redux"
import { changeLanguage } from "i18next"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"

import logo from "../../assets/images/logo_black.svg"
import appStoreBadge from "../../assets/images/MainPage/appStoreBadge.svg"
import googlePlayBadge from "../../assets/images/MainPage/googlePlayBadge.svg"
import womanImg from "../../assets/images/MainPage/card_woman_ukr.svg"
import womanImgEN from "../../assets/images/MainPage/card_woman_eng.svg"
import manImg from "../../assets/images/MainPage/card_man_ukr.svg"
import manImgEN from "../../assets/images/MainPage/card_man_eng.svg"
import iphoneImg from "../../assets/images/MainPage/mockup_ukr.png"
import iphoneImgEN from "../../assets/images/MainPage/mockup_eng.png"
import telegramIcon from "../../assets/images/MainPage/icon/telegram.svg"
import whatsAppIcon from "../../assets/images/MainPage/icon/whatsapp.svg"

import { getLanguage } from "../../redux/selectors"
import { setLanguage } from "../../redux/appReducer"
import LanguageSelector from "./LanguageSelector"
import Feedback from "./Feedback"
import { isUkraine } from "../../utils"
import feedbacks from "./mockFeedbaks"

//styles
import classes from "./MainPage.module.css"


const useScrollHorizontal = () => {
    const ref = useRef(null)

    useEffect(() => {
        const el = ref.current

        const onWheel = (e) => {
            if (e.deltaY === 0) return
            e.preventDefault()
            el.scrollBy({
                left: e.deltaY < 0 ? -100 : 100,
            })
        }
        el.addEventListener("wheel", onWheel)
        return () => el.removeEventListener("wheel", onWheel)
    }, [])
    return ref
}

const MainPage = ({ lng, setLanguage }) => {

    const [t] = useTranslation('mainPage')
    const nav = useNavigate()
    const myRef = useScrollHorizontal()

    const onLanguageSelect = (value) => {
        setLanguage(value)
        changeLanguage(value)
            .then(nav(isUkraine(value) ? "/ua" : "/",))
    }

    return (
        <main>
            <header className={classes.header}>
                <div className={classes.leftHeader}>
                    <div className={classes.logoFlex}>
                        <img className={classes.logo} src={logo} alt="logo" />
                        <div className={classes.mobileSelect}>
                            <LanguageSelector lng={lng} onOptionSelect={onLanguageSelect} />
                        </div>
                    </div>
                    <div className={classes.leftHeaderContainer}>
                        <div className={classes.headerTextContainer}>
                            <h1>{t('HEADER')}<span className={classes.rocket}>{t('HEADER_ROCKET')} <img src={`https://emojicdn.elk.sh/🚀`} alt={`🚀`} className={classes.smile} /></span></h1>
                            <p className={classes.headerText}>{t('HEADER_TEXT')}</p>
                        </div>
                        <button className={classes.redButton}
                            onClick={() => { nav(t('STEP1_URL')) }} >
                            {t('RED_BUTTON')}</button>
                    </div>
                </div>
                <div className={classes.rightHeader}>
                    <img className={`${classes.picTop} ${classes.pics}`} src={isUkraine(lng) ? womanImg : womanImgEN} alt="Woman" />
                    <img className={`${classes.picBot} ${classes.pics}`} src={isUkraine(lng) ? manImg : manImgEN} alt="Man" />
                    <div className={classes.rectangle}></div>
                    <LanguageSelector lng={lng} onOptionSelect={onLanguageSelect} />
                </div>
            </header>
            <div className={classes.sliderContainer}>
                <div className={classes.scrollHeader}>
                    {t('SCROLL_HEADER')}
                </div>

                <div className={classes.scrollContainer} ref={myRef} id="container">
                    {
                        feedbacks.map(({ name, date, text }, ind) => <Feedback key={ind} name={t(name)} date={date} text={t(text)} />)
                    }
                </div>
            </div>

            <div className={classes.descriptionContainer}>
                <div>
                    <div className={classes.descriptionHeader}>{t('DESCRIPTION_HEADER')}</div>
                    <div className={classes.descriptionLeft}>
                        <div>{t('DESCRIPTION_TEXT1')}</div>
                        <div>{t('DESCRIPTION_TEXT2')}</div>
                        <div>{t('DESCRIPTION_TEXT3')}</div>
                        <ol>
                            <li>{t('DESCRIPTION_LIST1')}</li>
                            <li>{t('DESCRIPTION_LIST2')}</li>
                            <li>{t('DESCRIPTION_LIST3')}</li>
                            <li>{t('DESCRIPTION_LIST4')}</li>
                        </ol>
                        <div>{t('DESCRIPTION_TEXT4')}</div>
                        <div>{t('DESCRIPTION_TEXT5')}</div>
                    </div>
                </div>
                <div className={classes.descriptionRight}>
                    <div>
                        <img className={classes.phoneImg} src={isUkraine(lng) ? iphoneImg : iphoneImgEN} alt="phone" />
                    </div>
                    <div className={classes.relativeButtons}>
                        <div className={classes.buttonsHeader}>{t('BUTTONS_HEADER')}</div>
                        <div className={classes.buttonsContainer}>
                            <a className={classes.badgeLink} target="_blank" rel="noreferrer" href="https://apps.apple.com/us/app/young-business-club/id1547614364">
                                <img src={appStoreBadge} alt="App Store" /></a>
                            <a className={classes.badgeLink} target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.ybc_next">
                                <img src={googlePlayBadge} alt="Google Play" /></a>
                        </div>
                    </div>
                </div>
            </div>

            <footer className={classes.footer}>
                <div className={classes.supportContainer}>
                    <div className={classes.footerHeader}>
                        {t('FOOTER_HEADER')}
                    </div>

                    <a className={classes.footerButton} target="_blank" rel="noreferrer" href={t('SUPPORT_LINK')}>
                        <img className={classes.telegramIcon} src={isUkraine(lng) ? telegramIcon : whatsAppIcon} alt="" />
                        <div>{t('SUPPORT_BUTTON')}</div></a>

                </div>
                <div className={classes.footerPadding}>

                    <div className={classes.footerFlex}>
                        <div className={classes.copyright}>© 2023 Business Match, {t('FOOTER_RIGHTS')}</div>
                        <div className={classes.linksContainer}>
                            {/* <Link className={classes.footerLink} to={t('COMMUNICATION_LINK')} >{t('COMMUNICATION_TEXT')}</Link>/ */}
                            <Link className={classes.footerLink} to={t("POLICY_LINK")} >{t('POLICY_TEXT')}</Link>
                        </div>
                    </div>
                    <div className={classes.withGap}>
                        <div>Andrii Ostapchuk</div>
                        <div>BUSINESS MATCH NETWORK - FZCO</div>
                        <div>+971 58 550 7447</div>
                        <div>0634378@gmail.com</div>
                        <div>info@businessmatch.ai</div>
                        <div>Company Address: Premises Number 39680 - 001, IFZA Business Park, DDP, Dubai Silicon Oasis, Dubai, United Arab Emirates
                        </div>
                    </div>
                </div>

            </footer>
        </main>
    )
}

const mapStateToProps = (state) => ({
    lng: getLanguage(state)
})

export default connect(mapStateToProps, { setLanguage })(MainPage)