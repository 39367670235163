import { API } from "../API/api";
import { getPairsOrIds, getStep2SelectedItems, getStep3SelectedItems } from "./selectors";

const SET_USERS_COUNT = 'endResults/SET-USERS-COUNT'

const localStorageObject = JSON.parse(localStorage.getItem('usersCount'))

const initialState = {
    usersCount: {...localStorageObject} || {
        interestsCount: null,
        passionsCount: null,
        workSpheresCount: null
    }
}

const endResultsReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_USERS_COUNT:

            return {
                ...state,
                usersCount: { ...action.countObj }
            }

        default:
            return state
    }
}

export default endResultsReducer;

//Acthion creators
const setUsersCount = (countObj) => ({ type: SET_USERS_COUNT, countObj })

//Thunk creators
export const getUsersCount = () => async (dispatch, getState) => {
    const state = getState()
    const countUsersForm = {
        passionsIds: getStep3SelectedItems(state),
        interestsIds: getPairsOrIds(state),
        workSpheresIds: getStep2SelectedItems(state)
    }

    try {
        const data = await API.countUsersByTags(countUsersForm)
        if (data) { 
            dispatch(setUsersCount(data)) 
            localStorage.setItem('usersCount', JSON.stringify(data))
        }
    } catch (error) {
        // console.log(error)
        // console.log(error.message)
    }
}