import React, { useEffect } from "react"
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next"

import Next from '../../components/Next/Next'
import SiteLayout from '../../components/SiteLayout/SiteLayout'
import Title from '../../components/Title/Title'
import StepFlexItem from './StepFlexItem'
import { step3SelectItem } from '../../redux/step3Reducer'
import { getPassionsByCategories, getIsStep3NextActive, getStep3SelectedItems } from '../../redux/selectors'
import { getUsersCount } from "../../redux/endResultsReducer"

//styles
import classes from './Step3.module.css'

const Step3 = ({ passionsByCategories, step3SelectItem, isNextActive, selectedItems, getUsersCount }) => {
    const [t] = useTranslation('step3')

    useEffect (() => {
        localStorage.setItem('step3SelectedItems', JSON.stringify(selectedItems))
      },[selectedItems])

    return (
        <SiteLayout>
            <div className={classes.nextPadTop}>
                <div className={classes.bodyFlex}>
                    <Title title={t('HEADER')} text={t('HEADER_TEXT')} />

                    <div className={classes.flexSport}>
                        {passionsByCategories.map((element) => <PassionComponent passionArray={element.passions}
                            title={element.title} selectedItems={selectedItems} step3SelectItem={step3SelectItem} key={element.titleEN} />)}
                        <div className={classes.whiteSpace}></div>
                    </div>

                </div>
                <Next currentStep={3} url={t('PIE_URL')} isActive={isNextActive} getUsersCount={getUsersCount}  >
                    {t('NEXT')}
                </Next>
            </div>
        </SiteLayout>
    )
}

const PassionComponent = ({ passionArray, selectedItems, step3SelectItem, title }) => {
    return (
        <div className={classes.passionContainer}>
            <div className={classes.passionHeader}>{title}</div>
            <div className={classes.flexContainer}>
                {passionArray.map((el) =>
                    <StepFlexItem key={el.id} smile={el.emoji} text={el.title}
                        id={el.id} isSelected={selectedItems.includes(el.id)} onItemClick={step3SelectItem} />)}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    passionsByCategories: getPassionsByCategories(state),
    isNextActive: getIsStep3NextActive(state),
    selectedItems: getStep3SelectedItems(state)
})

export default connect(mapStateToProps, { step3SelectItem, getUsersCount })(Step3)