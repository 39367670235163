import React, { useState } from "react"
import expand from "../../assets/images/icons/Expand.svg"
import checkMark from "../../assets/images/icons/Group.svg"
import { isUkraine } from "../../utils"

//styles
import classes from "./MainPage.module.css"


const LanguageSelector = ({ lng, onOptionSelect }) => {
    const [hideMode, changeHideMode] = useState(true)

    const onOptionClick = (lang) => {
        onOptionSelect(lang)
        changeHideMode(true)
    }
    return (
        <div className={classes.selectContainer}>
            <div className={classes.select} onClick={() => changeHideMode(false)}>
                <div className={classes.selectText}>{lng}</div>
                <img className={classes.selectImage} src={expand} alt="" />
            </div>
            {!hideMode && <div className={classes.optionsContainer}>
                <div className={classes.option} onClick={() => isUkraine(lng) && onOptionClick('en')}>
                    <div className={classes.text}>EN</div>
                    {!isUkraine(lng) && <img className={classes.optionImage} src={checkMark} alt="" />}
                </div>
                <hr className={classes.line} />
                <div className={classes.option} onClick={() => !isUkraine(lng) && onOptionClick('ua')}>
                    <div className={classes.text}>UA</div>
                    {isUkraine(lng) && <img className={classes.optionImage} src={checkMark} alt="" />}
                </div>
            </div>}
        </div>
    )
}

export default LanguageSelector