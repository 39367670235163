import React from "react"

//styles
import classes from './Title.module.css'

const Title = ({ title, text, smile }) => {
  return (<>
    <div className={classes.titleFlex} >
      <div className={classes.headerFlex}>
        <div className={classes.title}>
          {title}
          {smile && <img src={`https://emojicdn.elk.sh/${smile}`} alt={`${smile}`} className={classes.smile} />}
        </div>
      </div>
      <div className={classes.text}>{text}</div>
    </div>
  </>
  )
}

export default Title