import React from "react"

//styles
import classes from './Step1.module.css'

const StepGridItem = ({ smile, text, isSelected, onItemClick, id }) => {
    return (
        <div onClick={() => { onItemClick(id) }} className={`${isSelected ? classes.selected : undefined} ${classes.gridItem}`}>
            <div>
                <img className={classes.smile} src={`https://emojicdn.elk.sh/${smile}`} alt={`${smile}`} />
            </div>
            <div className={classes.itemText}>{text}</div>
        </div>
    )
}

export default StepGridItem