import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk"
import step1Reducer from "./step1Reducer";
import step2Reducer from "./step2Reducer";
import step3Reducer from "./step3Reducer";
import pieChartReducer from "./pieChartReducer";
import resultFormReducer from "./resultFormReducer";
import endResultsReducer from "./endResultsReducer";
import appReducer from "./appReducer";

const rootReducer = combineReducers({
    step1Page: step1Reducer,
    step2Page: step2Reducer,
    step3Page: step3Reducer,
    pieChartPage: pieChartReducer,
    resultFormPage: resultFormReducer,
    endResultsPage: endResultsReducer,
    app: appReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunkMiddleware)))

window.store = store

export default store;