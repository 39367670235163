import React from "react"
import { Field } from 'react-final-form'

//styles
import classes from "./FormsControls.module.css";

export const Input = (props) => {
    const { input, meta, ...restProps } = props

    return <input {...input} {...restProps} className={`${meta.error
        ? classes.error : undefined} ${classes.inputStyle}`}/>

}

export const createField = ({ name, component, type, placeholder, ...props }) => (
    <Field
        name={name}
        component={component}
        type={type}
        placeholder={placeholder}
        {...props}
    />
)