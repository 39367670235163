import React from "react"
import { Form } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import Title from '../../components/Title/Title'
import { Input, createField } from '../../components/common/FormsControls/FormsControls'
import SiteLayout from '../../components/SiteLayout/SiteLayout'
import { composeValidators, required, validEmail, validPhone } from '../../utils'
import errorIcon from '../../assets/images/icons/exclamationmark.svg'
import { registerUser } from "../../redux/resultFormReducer"

//styles
import classes from './ResultForm.module.css'


const ResultForm = ({ registerUser }) => {

    const isFormError = ({ errors, visited, }) => visited.phoneNumber && visited.email &&
        errors.email === 'REQUIRED' && errors.phoneNumber === 'REQUIRED'

    const navigate = useNavigate()
    const [t] = useTranslation('resultForm')

    return (
        <SiteLayout bgColor='#F2F2F2' nav={-2}>
            <div className={classes.nextPadTop}>
                <div className={classes.bodyFlex}>

                    <Form
                        initialValues={{

                        }}
                        onSubmit={(values) => {
                            registerUser(values)
                            navigate(t('RESULTS_URL'))
                            // send values to the cloud
                        }}
                        validate={({ phoneNumber, email }) => {
                            const errors = {}
                            errors.phoneNumber = composeValidators(required, validPhone)(phoneNumber)
                            errors.email = composeValidators(required, validEmail)(email)

                            return errors
                            // do validation here, and return errors object
                        }}
                        validateOnBlur={false}
                    >
                        {(props) => {
                            const { handleSubmit, submitting, valid } = props

                            const onFocusTel = (e) => {
                                const { target: { type, value } } = e
                                if (type !== 'tel' || value) return
                                e.target.value = "+"
                            }

                            return (
                                <form onSubmit={handleSubmit}>
                                    <div className={classes.formHeaderBox} >
                                        <Title title={t('HEADER')} text={t('HEADER_TEXT')} />
                                        <div>
                                            <div className={`${isFormError(props) ? classes.errorContainer : undefined} ${classes.inputsContainer}`}>
                                                <div className={`${isFormError(props) ? classes.formFlexError : undefined} ${classes.formFlex}`}>
                                                    {createField({ name: 'phoneNumber', component: Input, type: 'tel', placeholder: t("PHONE_PLACEHOLDER"), onClick: onFocusTel })}
                                                    <hr className={isFormError(props) ? classes.error : undefined} />
                                                    {createField({ name: 'email', component: Input, type: 'email', placeholder: t("EMAIL_PLACEHOLDER") })}
                                                </div>
                                            </div>
                                            {isFormError(props) &&
                                                <div className={classes.errorFlex}>
                                                    <img src={errorIcon} alt='WARNING!' />
                                                    <div className={classes.errorText}>{t('ERROR_TEXT')}</div>
                                                </div>}
                                        </div>
                                    </div>

                                    <div type="submit" onClick={handleSubmit} disabled={submitting} className={`${valid ? classes.nextActive : undefined} ${classes.next}`} >
                                        {t('NEXT')}
                                    </div>
                                </form>
                            )
                        }
                        }
                    </Form>
                </div>
            </div>
        </SiteLayout>
    )
}

const mapStateToProps = (state) => ({

})


export default connect(mapStateToProps, { registerUser })(ResultForm)