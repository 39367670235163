const SELECT_ELEMENT = 'step2/SELECT-ELEMENT'

const localStorageArray = JSON.parse(localStorage.getItem('step2SelectedItems'))

const initialState = {
  workSpheres: [

    {
      id: "AI",
      title: "AI",
      order: 19,
      titleEN: "AI",
      emoji: "🤖"
    },
    {
      id: "gayming",
      title: "Геймінг, VR i AR",
      order: 20,
      titleEN: "Gaming, VR and AR",
      emoji: "🎮"
    },
    {
      id: "it",
      title: "IT",
      order: 1,
      titleEN: "IT",
      emoji: "👨‍💻"
    },
    {
      id: "agro",
      title: "Агро",
      order: 9,
      titleEN: "Agriculture",
      emoji: "🌾"
    },
    {
      id: "finance",
      title: "Фінанси",
      order: 12,
      titleEN: "Finances",
      emoji: "🏦"
    },
    {
      id: "amazon",
      title: "Амазон",
      order: 13,
      titleEN: "Amazon",
      emoji: "🛍️"
    },
    {
      id: "horeca",
      title: "HoReCa",
      order: 14,
      titleEN: "HoReCa",
      emoji: "👨‍🍳"
    },
    {
      id: "ecommerce",
      title: "E-Commerce",
      order: 17,
      titleEN: "E-Commerce",
      emoji: "🏬"
    },
    {
      id: "design",
      title: "Дизайн",
      order: 21,
      titleEN: "Design",
      emoji: "🎨"
    },
    {
      id: "energy",
      title: "Енергія",
      order: 22,
      titleEN: "Energy",
      emoji: "⛽️"
    },
    {
      id: "events_preparation",
      title: "Організація заходів",
      order: 24,
      titleEN: "Event organization",
      emoji: "🎠"
    },
    {
      id: "marketing_and_content",
      title: "Реклама, контент і маркетинг",
      order: 27,
      titleEN: "Advertising, content and marketing",
      emoji: "📺"
    },
    {
      id: "entertainment",
      title: "Розваги",
      order: 28,
      titleEN: "Entertainment",
      emoji: "🎢"
    },

    {
      id: "cport_and_fitness",
      title: "Спорт та фітнес",
      order: 30,
      titleEN: "Sport and fitness",
      emoji: "⚽️"
    },
    {
      id: "education",
      title: "Освіта",
      order: 32,
      titleEN: "Education",
      emoji: "👨‍🎓"
    },
    {
      id: "hr_rec",
      title: "HR & рекрутинг",
      order: 33,
      titleEN: "HR & recruitment",
      emoji: "📃"
    },
    {
      id: "law_and_consulting",
      title: "Юридичні послуги та консалтинг",
      order: 34,
      titleEN: "Legal and consulting services",
      emoji: "⚖️"
    },
    {
      id: "cryptocurrency_exchange",
      title: "Cryptocurrency Exchange",
      order: 35,
      titleEN: "Cryptocurrency Exchange",
      emoji: "💸"
    },
    {
      id: "dao",
      title: "DAO: Decentralized Autonomous Organizations",
      order: 36,
      titleEN: "DAO: Decentralized Autonomous Organizations",
      emoji: "💶"
    },
    {
      id: "dapps",
      title: "DApps: Decentralisation App",
      order: 37,
      titleEN: "DApps: Decentralisation App",
      emoji: "💴"
    },
    {
      id: "defi",
      title: "DeFi: Decentralized Finance",
      order: 38,
      titleEN: "DeFi: Decentralized Finance",
      emoji: "💵"
    },
    {
      id: "MilitaryStudies",
      titleEN: "Military studies",
      title: "Військова справа",
      order: 40,
      emoji: "⚔️"
    },
    {
      id: "SocialEntrepreneurship",
      emoji: "🤝",
      titleEN: "Social entrepreneurship",
      title: "Соціальне підприємництво",
      order: 41
    },
    {
      id: "Consulting",
      emoji: "📊",
      titleEN: "Consulting",
      title: "Консалтинг",
      order: 42
    },
    {
      id: "Construction",
      emoji: "🏗️",
      titleEN: "Construction",
      title: "Будівництво",
      order: 43
    },

    {
      id: "media",
      title: "Медіа",
      order: 15,
      titleEN: "Media",
      emoji: "🖨"
    },
    {
      id: "metallurgy",
      title: "Металургія",
      order: 23,
      titleEN: "Metallurgy",
      emoji: "🔩"
    },
    {
      id: "nft",
      title: "NFT: Non-fungible token",
      order: 39,
      titleEN: "NFT: Non-fungible token",
      emoji: "💰"
    },
    {
      id: "outsourcing",
      title: "Аутсорсинг",
      order: 2,
      titleEN: "Outsource",
      emoji: "💬"
    },
    {
      id: "pharmacy",
      title: "Фармацевтика",
      order: 8,
      titleEN: "Pharmacy",
      emoji: "💊"
    },
    {
      id: "product",
      title: "Продукт",
      order: 3,
      titleEN: "Product",
      emoji: "📀"
    },
    {
      id: "production",
      title: "Виробництво",
      order: 6,
      titleEN: "Production",
      emoji: "🏭"
    },
    {
      id: "realty",
      title: "Нерухомість",
      order: 5,
      titleEN: "Real estate",
      emoji: "🏙️"
    },
    {
      id: "retail",
      title: "Роздрібна торгівля",
      order: 7,
      titleEN: "Retail",
      emoji: "🏪"
    },
    {
      id: "services",
      title: "Послуги",
      order: 4,
      titleEN: "Services",
      emoji: "🏤"
    },
    {
      id: "social_media_networking",
      title: "Соціальні медіа та нетворкінг",
      order: 29,
      titleEN: "Social media and networking",
      emoji: "🗨️"
    },
    {
      id: "software_for_industries",
      title: "Програмні рішення для підприємств",
      order: 26,
      titleEN: "Software solutions for businesses",
      emoji: "💻"
    },
    {
      id: "telecommunications",
      title: "Телекомунікації та комунальні послуги",
      order: 31,
      titleEN: "Telecommunications and public utilities",
      emoji: "📲"
    },
    {
      id: "tourism_and_hotels",
      title: "Туризм та готельний бізнес",
      order: 25,
      titleEN: "Tourism and hotel business",
      emoji: "🚁"
    },
    {
      id: "transport",
      title: "Транспорт",
      order: 18,
      titleEN: "Transport",
      emoji: "🚅"
    },
    {
      id: "venture",
      order: 10,
      title: "Венчурний капітал",
      titleEN: "Venture capital",
      emoji: "🧾"
    },
    {
      id: "web3",
      order: 16,
      title: "Web3 Browsers",
      titleEN: "WEB3 Browsers",
      emoji: "📉"
    }
  ],
  isNextActive: !!localStorageArray ? !!localStorageArray.length : false,
  selectedItems: localStorageArray || []
}

const step2Reducer = (state = initialState, action) => {
  switch (action.type) {

    case SELECT_ELEMENT:

      const { selectedItems } = state
      const array =  selectedItems.includes(action.id)
      ? [...selectedItems.filter(id => action.id !== id)]
      : [...selectedItems, action.id]
      
      return {
        ...state,
        isNextActive: !!array.length,
        selectedItems: [...array]
        
      }

    default:
      return state
  }
}

export default step2Reducer;

//Action Creators
export const step2SelectItem = (id) => ({ type: SELECT_ELEMENT, id })

