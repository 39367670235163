import React from "react"
import { useTranslation } from "react-i18next"

import SiteLayout from "../../components/SiteLayout/SiteLayout"
import Title from "../../components/Title/Title"
import AnimatePercent from "./AnimatePercent"

//styles
import classes from './PieChart.module.css'



const PieChart = ({ duration }) => {
    const [t] = useTranslation('pieChart')
    return (
        <SiteLayout>
            <div className={classes.pieContainer}>
                <Title title={t('HEADER')} className={classes.inline} smile={`🧐`} />
                <div className={classes.pieFlex}>
                    <div className={`${classes.pie} ${classes.animate} ${classes.noRound}`}
                        style={{ "--duration": `${duration / 1000}s` }}>
                        <AnimatePercent to={100} duration={duration} url={t('FORM_URL')} />
                    </div>
                    <div className={classes.rightSide}>
                        <div className={classes.smileAndText}>
                            <div > <img src={`https://emojicdn.elk.sh/🚀`} className={classes.smile} alt='🚀' /></div>
                            <div className={classes.text}>{t('ITEM1')}</div>
                        </div>
                        <div className={classes.smileAndText}>
                            <div > <img src={`https://emojicdn.elk.sh/🤑`} className={classes.smile} alt='🤑' /></div>
                            <div className={classes.text}>{t('ITEM2')}</div>
                        </div>
                        <div className={classes.smileAndText}>
                            <div > <img src={`https://emojicdn.elk.sh/🥹`} className={classes.smile} alt='🥹' /></div>
                            <div className={classes.text}>{t('ITEM3')}</div>
                        </div>
                        <div className={classes.blur} style={{ "--duration": `${duration / 1000}s` }}></div>
                    </div>
                </div>
            </div>
        </SiteLayout>

    )
}

export default PieChart