import React from "react"

//styles
import classes from './EndResults.module.css'

const ResultsItem = ({ itemsArray, header }) => {
    return (
        <div className={classes.resultsItem}>
            <div className={classes.itemHeader}>{header}</div>
            {itemsArray.map((element) => <ResultsSubItem key={element.id} emoji={element.emoji} text={element.title} />)}
        </div>
    )
}

const ResultsSubItem = ({ emoji, text }) => {
    return (
        <div className={classes.flexItem}>
            <div>
                <img src={`https://emojicdn.elk.sh/${emoji}`} alt={`${emoji}`} className={classes.smile} />
            </div>
            <div className={classes.text}>{text}</div>
        </div>
    )

}

export default ResultsItem