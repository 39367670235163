const REQUIRED = "REQUIRED"
const WRONG = "WRONG"


//validators
export const validEmail = (value) => {
    // eslint-disable-next-line
    const reg = /^[\w-\.]+@[\w-]+\.[a-z]{2,4}$/i
    return (reg.test(value)) ? undefined : WRONG
}
export const validPhone = (value) => {
    // eslint-disable-next-line
    const reg = /^[\d\+][\d\(\)\ -]{4,14}\d$/
    return ((reg.test(value)) ? undefined : WRONG)
}

export const required = (value) => {
    return (value && (typeof value !== "string" || value.trim() !== "")) ? undefined : REQUIRED
}

export const composeValidators = (...validators) => (value) =>
    validators.reduce((error, validator) => error || validator(value), undefined)

//translate

export const isUkraine = lng => lng === "ua"

export const getTitleEN = (obj, lng, key = "title",) => {
    return {
        ...obj,
        [key]: isUkraine(lng) ? obj[key] : obj[`${key}EN`]
    }
}

