import React, { useEffect } from "react"
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next"

import Next from '../../components/Next/Next'
import SiteLayout from '../../components/SiteLayout/SiteLayout'
import Title from '../../components/Title/Title'
import StepGridItem from './StepGridItem'
import { getIsStep1NextActive, getStep1SelectedItems, getTinderInterests } from '../../redux/selectors'
import { step1SelectItem } from '../../redux/step1Reducer'

//styles
import classes from './Step1.module.css'

const Step1 = ({ arrayItems, step1SelectItem, isNextActive, selectedItems }) => {
  const { t } = useTranslation('step1')

  useEffect (() => {
    localStorage.setItem('step1SelectedItems', JSON.stringify(selectedItems))
  },[selectedItems])

   

  return (
    <SiteLayout>
      <div className={classes.nextPadTop}>
        <div className={classes.bodyFlex}>
          <Title title={t('HEADER')} text={t('HEADER_TEXT')} />

          <div className={classes.gridContainer}>
            {arrayItems.map((el) => <StepGridItem key={el.id} id={el.id} smile={el.emoji}
              text={el.title} onItemClick={step1SelectItem} isSelected={selectedItems.includes(el.id)} />)}
          </div>

        </div>

        <Next currentStep={1} url={t('STEP2_URL')} isActive={isNextActive} blur={false}>
          {t('NEXT')}
        </Next>

      </div>
    </SiteLayout>
  )
}

const mapStateToProps = (state) => ({
  arrayItems: getTinderInterests(state),
  isNextActive: getIsStep1NextActive(state),
  selectedItems: getStep1SelectedItems(state)
})

export default connect(mapStateToProps, { step1SelectItem })(Step1)
