import React, { useEffect } from "react"
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next"

import Next from '../../components/Next/Next'
import Title from '../../components/Title/Title'
import StepScrollItem from './StepScrollItem'
import SiteLayout from '../../components/SiteLayout/SiteLayout'
import { getIsStep2NextActive, getWorkSpheres, getStep2SelectedItems } from '../../redux/selectors'
import { step2SelectItem } from '../../redux/step2Reducer'

//styles
import classes from './Step2.module.css'

const Step2 = ({ workSphereArray, isNextActive, step2SelectItem, selectedItems }) => {
    const [t] = useTranslation('step2')

  useEffect (() => {
    localStorage.setItem('step2SelectedItems', JSON.stringify(selectedItems))
  },[selectedItems])
    
    return (
        <SiteLayout>
            <div className={classes.nextPadTop}>
                <div className={classes.bodyFlex}>
                    <Title title={t('HEADER')} text={t('HEADER_TEXT')} />
                    <div className={classes.scrollContainer}>
                        {workSphereArray.map((el) =>
                            <StepScrollItem key={el.id} smile={el.emoji} text={el.title} id={el.id}
                                isSelected={selectedItems.includes(el.id)} onItemClick={step2SelectItem} />)}
                        <div className={classes.whiteSpace}></div>
                    </div>
                </div>
                <Next currentStep={2} url={t('STEP3_URL')} isActive={isNextActive}>
                    {t('NEXT')}
                </Next>
            </div>
        </SiteLayout>
    )
}

const mapStateToProps = (state) => ({
    workSphereArray: getWorkSpheres(state),
    isNextActive: getIsStep2NextActive(state),
    selectedItems: getStep2SelectedItems(state)
})

export default connect(mapStateToProps, { step2SelectItem })(Step2)