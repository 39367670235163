const feedbacks = [{
    name: "FEEDBACK1_NAME",
    date: "27.06.2023",
    text: "FEEDBACK1_TEXT",
},
{
    name: "FEEDBACK2_NAME",
    date: "27.06.2023",
    text: "FEEDBACK2_TEXT",
},
{
    name: "FEEDBACK3_NAME",
    date: "14.10.2023",
    text: "FEEDBACK3_TEXT",
},
{
    name: "FEEDBACK4_NAME",
    date: "13.06.2023",
    text: "FEEDBACK4_TEXT",
},
{
    name: "FEEDBACK5_NAME",
    date: "13.10.2023",
    text: "FEEDBACK5_TEXT",
},
]

export default feedbacks