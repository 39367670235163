import { isUkraine } from "../utils"

const CHANGE_LANGUAGE = 'app/CHANGE-LANGUAGE'

const initialState = {
  lng: 'en',
  whitelist: ["ua"],
  basename: ''
}

const appReducer = (state = initialState, action) => {

  switch (action.type) {

    case CHANGE_LANGUAGE:
      return {
        ...state,
        lng: action.lng,
        basename: isUkraine(action.lng) ? '/ua' : "/"
      }

    default:
      return state
  }
}

export default appReducer;

//Action Creators
export const setLanguage = (lng) => ({ type: CHANGE_LANGUAGE, lng })

