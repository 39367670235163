import React from "react"

//styles
import classes from './Step2.module.css'

const StepScrollItem = ({ smile, text, id, isSelected, onItemClick }) => {
    return (
        <div className={`${isSelected ? classes.active : undefined} ${classes.scrollItem}`}
            onClick={() => { onItemClick(id) }}>
            <div><img src={`https://emojicdn.elk.sh/${smile}`} alt={`${smile}`} className={classes.smile} /></div>
            <div>{text}</div>
        </div>
    )
}

export default StepScrollItem