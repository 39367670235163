import React from "react"

import Body from "../Body/Body"
import Footer from "../Footer/Footer"
import Header from "../Header/Header"

//styles
import classes from './SiteLayout.module.css'

const SiteLayout = ({ children, bgColor = "#fff", nav = -1, footerWidth, footerLeft }) => {
    return (
        <>
            <div className={classes.siteLayout} style={{ backgroundColor: `${bgColor}` }}>
                <div className={classes.contentContainer}>
                    <Header nav={nav} />
                    <Body>{children}</Body>
                    <Footer width={footerWidth} left={footerLeft} />
                </div>
            </div>

        </>
    )
}

export default SiteLayout