const policy = [
    {
        heading: "LIST_HEADER1",
        text: "LIST_TEXT1",
    },
    {
        heading: "LIST_HEADER2",
        text: "LIST_TEXT2",
    },
    {
        heading: "LIST_HEADER3",
        text: "LIST_TEXT3",
    },
 
]


export default policy
