import React from "react"

//styles
import classes from "./Paragraph.module.css"

const Paragraph = ({ heading, text, bold }) => {
    return (

        <div className={classes.listItem}>
            <div className={classes.heading}>{heading}</div>
            <p className={classes.text}><span style={{ color: "#0F1214" }}>{bold}</span>{text}</p>
        </div>

    )
}

export default Paragraph