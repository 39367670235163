const SELECT_ELEMENT = 'step1/SELECT-ELEMENT'

const localStorageArray = JSON.parse(localStorage.getItem('step1SelectedItems'))

const initialState = {

  tinderInterests: [
    {
      id: "investment_projects",
      public: true,
      title: "Проєкти для інвестицій",
      pair: "search_inverstor",
      titleEN: "Projects for investment",
      emoji: "💰"
    },
    {
      id: "surroundings",
      public: true,
      title: "Професійне спілкування",
      titleEN: "Professional communication",
      emoji: "😎"
    },
    {
      id: "mentor",
      public: true,
      title: "Можу бути ментором",
      pair: "search_mentor",
      titleEN: "I could be a mentor",
      emoji: "👨‍🏫"
    },
    {
      id: "search_inverstor",
      public: true,
      title: "Інвестора",
      pair: "investment_projects",
      emoji: "🤑",
      titleEN: "Investor"
    },
    {
      id: "interesting_people",
      public: true,
      titleEN: "Interesting people",
      emoji: "🥹",
      title: "Цікавих людей"
    },
    {
      id: "search_mentor",
      public: true,
      title: "Шукаю ментора",
      pair: "mentor",
      titleEN: "Looking for mentor",
      emoji: "🧑‍🎓"
    },

  ],
  isNextActive: !!localStorageArray ? !!localStorageArray.length : false,
  selectedItems: localStorageArray || []
}

const step1Reducer = (state = initialState, action) => {

  switch (action.type) {

    case SELECT_ELEMENT:
      const { selectedItems } = state
      let array = selectedItems
      array = selectedItems.includes(action.id)
        ? [...selectedItems.filter(id => action.id !== id)]
        : array.length < 2 ? [...selectedItems, action.id] : [...selectedItems]

      return {
        ...state,
        isNextActive: !!array.length,
        selectedItems: [...array]
      }

    default:
      return state
  }
}

export default step1Reducer;

//Action Creators
export const step1SelectItem = (id) => ({ type: SELECT_ELEMENT, id })

