import React from "react"
import { useTranslation } from "react-i18next"

//styles
import classes from './Footer.module.css'

const Footer = ({ width, left }) => {
  const [t] = useTranslation('footer')
  return (
    <div className={classes.footer} style={{ width: width }}>
      © 2023 Business Match, {t('FOOTER')}.
    </div>
  )
}

export default Footer