import React from "react"
import { useNavigate } from "react-router-dom"

import logo from "../../assets/images/logo_black.svg"
import forward from "../../assets/images/icons/forwardLeft.svg"

//styles
import classes from './Header.module.css'

const Header = ({ nav }) => {
  const navigate = useNavigate()
  return (
    <div className={classes.headerFlex}>
        <div className={classes.forwardContainer}>
          <img className={classes.forward} onClick={() => navigate(nav)} src={forward} alt='forward-left' />
        </div>
      <div className={classes.logoContainer}>

        <img className={classes.headerLogo} src={logo} alt='logo' />
      </div>
    </div>
  )
}

export default Header