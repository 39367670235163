const SELECT_ELEMENT = 'step3/SELECT-ELEMENT'

const localStorageArray = JSON.parse(localStorage.getItem('step3SelectedItems'))

const initialState = {

  passionsByCategories: [
    {
      id: "sport",
      title: "Спорт",
      order: 1,
      titleEN: "Sport",
      passions: [
        {
          id: "GYM_but_gym",
          category: "sport",
          emoji: "🏋️‍♂️",
          titleEN: "GYM",
          title: "Тренажерний зал",
          order: 0
        },
        {
          id: "IronMan",
          category: "sport",
          title: "IronMan",
          titleEN: "IronMan",
          emoji: "⛹️",
          order: 0
        },
        {
          id: "Jet_Ski",
          category: "sport",
          title: "Гідроцикл",
          titleEN: "Jet Ski",
          emoji: "🛥",
          order: 0
        },
        {
          id: "acrobatics",
          category: "sport",
          title: "Акробатика",
          titleEN: "Acrobatics",
          emoji: "🤸",
          order: 0
        },
        {
          id: "amateur_wrestling",
          category: "sport",
          title: "Боротьба",
          titleEN: "Wrestling",
          emoji: "🤼",
          order: 0
        },
        {
          id: "american_football",
          category: "sport",
          title: "Американський футбол",
          titleEN: "American football",
          emoji: "🏈",
          order: 0
        },
        {
          id: "archery",
          category: "sport",
          title: "Стрільба з лука",
          titleEN: "Archery",
          emoji: "🏹",
          order: 0
        },
        {
          id: "atv",
          category: "sport",
          title: "Квадроцикли",
          titleEN: "ATVs",
          emoji: "🛺",
          order: 0
        },
        {
          id: "badminton",
          category: "sport",
          title: "Бадмінтон",
          order: 10,
          titleEN: "Badminton",
          emoji: "🏸"
        },
        {
          id: "baseball",
          category: "sport",
          title: "Бейсбол",
          order: 12,
          titleEN: "Baseball",
          emoji: "⚾️"
        },
        {
          id: "basketball",
          category: "sport",
          title: "Баскетбол",
          order: 11,
          titleEN: "Basketball",
          emoji: "⛹️"
        },
        {
          id: "bicycle",
          category: "sport",
          title: "Велосипед",
          titleEN: "Bicycle",
          emoji: "🚴",
          oder: 0
        },
        {
          id: "bike",
          category: "sport",
          title: "Байк",
          titleEN: "Bike",
          emoji: "🏍",
          order: 0
        },
        {
          id: "billiard",
          category: "sport",
          title: "Більярд",
          titleEN: "Billiard",
          emoji: "🎱",
          order: 0
        },
        {
          id: "bodybuilding",
          category: "sport",
          order: 5,
          titleEN: "Bodybuilding",
          title: "Бодібілдінг",
          emoji: "🤾‍♀️"
        },
        {
          id: "bodyflex",
          category: "sport",
          title: "Бодіфлекс",
          titleEN: "Bodyflex",
          emoji: "🏋",
          order: 0
        },
        {
          id: "bowling",
          category: "sport",
          title: "Боулінг",
          titleEN: "Bowling",
          emoji: "🎳",
          order: 0
        },
        {
          id: "boxing",
          category: "sport",
          title: "Бокс",
          order: 22,
          titleEN: "Boxing",
          emoji: "👊"
        },
        {
          id: "buggy",
          category: "sport",
          title: "Баггі",
          titleEN: "Buggy",
          emoji: "🛺",
          order: 0
        },
        {
          id: "bungiejamping",
          category: "sport",
          title: "Банджі-джампінг",
          titleEN: "Bungie-jumping",
          emoji: "🕴",
          order: 0
        },
        {
          id: "carting",
          category: "sport",
          titleEN: "Carting",
          emoji: "🏎",
          order: 0,
          title: "Картинг"
        },
        {
          id: "catamaran",
          category: "sport",
          title: "Катамаран",
          titleEN: "Catamaran",
          emoji: "🚤",
          order: 0
        },
        {
          id: "cickboxing",
          category: "sport",
          title: "Кікбоксинг",
          titleEN: "Kickboxing",
          emoji: "🥊",
          order: 0
        },
        {
          id: "climbing",
          category: "sport",
          title: "Скелелазіння",
          titleEN: "Rock climbing",
          emoji: "🧗",
          order: 0
        },
        {
          id: "cold_water",
          category: "sport",
          title: "Моржування",
          titleEN: "Cold-water swimming",
          emoji: "🤽",
          order: 0
        },
        {
          id: "cricket",
          category: "sport",
          title: "Крикет",
          order: 7,
          titleEN: "Cricket",
          emoji: "🏏"
        },
        {
          id: "crossfit",
          category: "sport",
          title: "Кросфіт",
          order: 4,
          titleEN: "Crossfit",
          emoji: "🏋️"
        },
        {
          id: "cybersport",
          category: "sport",
          title: "Кібер-спорт",
          titleEN: "Cybersport",
          emoji: "💻",
          order: 0
        },
        {
          id: "cycling",
          category: "sport",
          title: "Велогонки",
          order: 20,
          titleEN: "Bicycle racing",
          emoji: "🚲"
        },
        {
          id: "diving",
          category: "sport",
          title: "Дайвінг",
          titleEN: "Diving",
          emoji: "🤿",
          order: 0
        },
        {
          id: "drift",
          category: "sport",
          title: "Дріфт",
          titleEN: "Drift",
          emoji: "🏎",
          order: 0
        },
        {
          id: "enduro",
          category: "sport",
          title: "Ендуро",
          titleEN: "Enduro",
          emoji: "🔅",
          order: 0
        },
        {
          id: "energy_practices",
          category: "sport",
          title: "Енергетичні практики",
          titleEN: "Energy practices",
          emoji: "💡",
          order: 0
        },
        {
          id: "equestrian_sport",
          category: "sport",
          title: "Кінний спорт",
          titleEN: "Equestrian sports",
          emoji: "🏇",
          order: 0
        },
        {
          id: "extreme_driving",
          category: "sport",
          title: "Екстремальне керування",
          titleEN: "Extreme driving",
          emoji: "👨",
          order: 0
        },
        {
          id: "extreme_sports",
          category: "sport",
          title: "Екстремальні види спорту",
          titleEN: "Extreme sports",
          emoji: "🪂",
          order: 0
        },
        {
          id: "extremes ",
          category: "sport",
          title: "Екстрим",
          titleEN: "Extreme",
          emoji: "🚥",
          order: 0
        },
        {
          id: "fencing",
          category: "sport",
          title: "Фехтування",
          titleEN: "Fencing",
          emoji: "⚔️",
          order: 0
        },
        {
          id: "fieldHockey",
          category: "sport",
          title: "Хокей на траві",
          order: 14,
          titleEN: "Field hockey",
          emoji: "🏑"
        },
        {
          id: "fishing",
          category: "sport",
          title: "Рибалка",
          titleEN: "Fishing",
          emoji: "🎣",
          order: 0
        },
        {
          id: "fitnes",
          category: "sport",
          title: "Фітнес",
          titleEN: "Fitness",
          emoji: "🏃",
          order: 0
        },
        {
          id: "flying",
          category: "sport",
          title: "Польоти літаками",
          titleEN: "Flying by airplane",
          emoji: "🛩",
          order: 0
        },
        {
          id: "football",
          category: "sport",
          title: "Футбол",
          order: 6,
          titleEN: "Football",
          emoji: "🏟"
        },
        {
          id: "frisbee",
          category: "sport",
          title: "Фрісбі",
          titleEN: "Frisbee",
          emoji: "🥏",
          order: 0
        },
        {
          id: "golf",
          category: "sport",
          title: "Гольф",
          order: 16,
          titleEN: "Golf",
          emoji: "🏌️"
        },
        {
          id: "gym",
          category: "sport",
          title: "Спортзал",
          emoji: "🏋️",
          order: 0,
          titleEN: "Sports hall"
        },
        {
          id: "gymnastics",
          category: "sport",
          title: "Гімнастика",
          titleEN: "Gymnastics",
          emoji: "🤸",
          order: 0
        },
        {
          id: "hadball",
          category: "sport",
          title: "Гандбол",
          titleEN: "Handball",
          emoji: "🤾",
          order: 0
        },
        {
          id: "hiking",
          category: "sport",
          title: "Походи в гори",
          order: 24,
          titleEN: "Hiking in the mountains",
          emoji: "🗻"
        },
        {
          id: "horse_riding",
          category: "sport",
          title: "Верхова їзда",
          titleEN: "Horseback riding",
          emoji: "🏇",
          order: 0
        },
        {
          id: "hunting",
          category: "sport",
          title: "Полювання",
          titleEN: "Hunting",
          emoji: "🏹",
          order: 0
        },
        {
          id: "iceHockey",
          category: "sport",
          order: 13,
          titleEN: "Hockey",
          emoji: "🏑",
          title: "Хокей"
        },
        {
          id: "ice_skates",
          category: "sport",
          titleEN: "Ice skates",
          emoji: "⛸",
          order: 0,
          title: "Катання на ковзанах"
        },
        {
          id: "jiu_jitsu",
          category: "sport",
          title: "Джиу джитсу",
          titleEN: "Jiu Jitsu",
          emoji: "🥋",
          order: 0
        },
        {
          id: "judo",
          category: "sport",
          title: "Дзюдо",
          titleEN: "Judo",
          emoji: "👘",
          order: 0
        },
        {
          id: "karate",
          category: "sport",
          title: "Карате",
          titleEN: "Karate",
          emoji: "🤛",
          order: 0
        },
        {
          id: "kayaking",
          category: "sport",
          title: "Каякінг",
          titleEN: "Kayaking",
          emoji: "⛵️",
          order: 0
        },
        {
          id: "kayaks",
          category: "sport",
          title: "Байдарки",
          titleEN: "Kayaks",
          emoji: "🛶",
          order: 0
        },
        {
          id: "kiteserfing",
          category: "sport",
          title: "Кайтсерфінг",
          titleEN: "Kitesurfing",
          emoji: "🏄",
          order: 0
        },
        {
          id: "krav_maga",
          category: "sport",
          title: "Крав-мага",
          titleEN: "Krav Maga",
          emoji: "🤛",
          order: 0
        },
        {
          id: "martial_arts",
          category: "sport",
          title: "Бойові мистецтва",
          titleEN: "Martial arts",
          emoji: "🥋",
          order: 0
        },
        {
          id: "meditation",
          category: "sport",
          title: "Медитація",
          titleEN: "Meditation",
          emoji: "🧘",
          order: 0
        },
        {
          id: "mini-football",
          category: "sport",
          title: "Міні-футбол",
          emoji: "🥅",
          titleEN: "Mini-football",
          order: 0
        },
        {
          id: "mma",
          category: "sport",
          title: "Змішані єдиноборства",
          order: 0,
          titleEN: "Mixed Martial Arts",
          emoji: "🤼‍♂️"
        },
        {
          id: "morning_walks",
          category: "sport",
          title: "Ранкові прогулянки",
          emoji: "🚶",
          titleEN: "Morning walks",
          order: 0
        },
        {
          id: "motorSports",
          category: "sport",
          title: "Мотогонки",
          order: 21,
          emoji: "🏍",
          titleEN: "Motorcycle racing"
        },
        {
          id: "motorcycle ",
          category: "sport",
          title: "Мотоцикл",
          emoji: "🏍",
          titleEN: "Motorcycle",
          order: 0
        },
        {
          id: "mountaineering",
          category: "sport",
          title: "Альпінізм",
          order: 23,
          emoji: "⛰",
          titleEN: "Mountaineering"
        },
        {
          id: "off_road",
          category: "sport",
          title: "Офф-роуд",
          titleEN: "Off-road",
          order: 0,
          emoji: "🛤"
        },
        {
          id: "paddleboarding",
          category: "sport",
          title: "Паддлбордінг",
          emoji: "🤸",
          titleEN: "Paddleboarding",
          order: 0
        },
        {
          id: "paintball",
          category: "sport",
          title: "Пейнтбол",
          emoji: "🎨",
          titleEN: "Paintball",
          order: 0
        },
        {
          id: "parachute",
          category: "sport",
          title: "Парашут",
          emoji: "🪂",
          titleEN: "Parachute",
          order: 0
        },
        {
          id: "paragliding",
          category: "sport",
          title: "Парагвайдинг",
          emoji: "🤿",
          titleEN: "Paragliding",
          order: 0
        },
        {
          id: "paraplanes",
          category: "sport",
          title: "Параплани",
          titleEN: "Paraplanes",
          order: 0,
          emoji: "👨‍🚀"
        },
        {
          id: "pilates",
          category: "sport",
          title: "Пілатес",
          order: 3,
          emoji: "🤸",
          titleEN: "Pilates"
        },
        {
          id: "pingPong",
          category: "sport",
          emoji: "🏓",
          titleEN: "Ping Pong",
          title: "Пінг Понг",
          order: 0
        },
        {
          id: "poker",
          category: "sport",
          title: "Покер",
          emoji: "🃏",
          titleEN: "Poker",
          order: 0
        },
        {
          id: "pool",
          category: "sport",
          title: "Басейн",
          order: 27,
          emoji: "🏊",
          titleEN: "Swimming pool"
        },
        {
          id: "powerlifting",
          category: "sport",
          title: "Силові тренування",
          emoji: "💪",
          titleEN: "Powerlifting",
          order: 0
        },
        {
          id: "pull-up_bar",
          category: "sport",
          title: "Турнік",
          emoji: "🎽",
          titleEN: "Pull-up bar",
          order: 0
        },
        {
          id: "races",
          category: "sport",
          title: "Гонки",
          emoji: "🏁",
          titleEN: "Races",
          order: 0
        },
        {
          id: "rafting",
          category: "sport",
          emoji: "🚵",
          titleEN: "Rafting",
          title: "Рафтинг",
          order: 0
        },
        {
          id: "rally",
          category: "sport",
          title: "Раллі",
          order: 25,
          emoji: "🛺",
          titleEN: "Rally"
        },
        {
          id: "rhythmic_gymnastics",
          category: "sport",
          title: "Художня гімнастика",
          emoji: "🤸",
          titleEN: "Rhythmic gymnastics",
          order: 0
        },
        {
          id: "roadjumping",
          category: "sport",
          title: "Роуд-джампінг",
          emoji: "🕴",
          titleEN: "Road jumping",
          order: 0
        },
        {
          id: "rollers",
          category: "sport",
          title: "Ролики",
          emoji: "⛸",
          titleEN: "Rollers",
          order: 0
        },
        {
          id: "rowing",
          category: "sport",
          title: "Веслування",
          emoji: "🚣",
          titleEN: "Rowing",
          order: 0
        },
        {
          id: "rugby",
          category: "sport",
          title: "Регбі",
          order: 17,
          emoji: "🏉",
          titleEN: "Rugby"
        },
        {
          id: "running",
          category: "sport",
          title: "Біг",
          order: 1,
          emoji: "🏃",
          titleEN: "Running"
        },
        {
          id: "sailing_regatta",
          category: "sport",
          title: "Вітрильна регата",
          emoji: "⛵️",
          titleEN: "Sailing regatta",
          order: 0
        },
        {
          id: "sailing_sport",
          category: "sport",
          title: "Вітрильний спорт",
          emoji: "⛵️",
          titleEN: "Sailing sport",
          order: 0
        },
        {
          id: "shooting",
          category: "sport",
          title: "Стрільба",
          emoji: "🏹",
          titleEN: "Shooting",
          order: 0
        },
        {
          id: "skateboarding",
          category: "sport",
          title: "Скейтбординг",
          emoji: "🛹",
          titleEN: "Skateboarding",
          order: 0
        },
        {
          id: "skiing",
          category: "sport",
          order: 19,
          emoji: "⛷",
          titleEN: "Skiing",
          title: "Лижі"
        },
        {
          id: "sniping",
          category: "sport",
          title: "Снайперство",
          emoji: "🎯",
          titleEN: "Sniping",
          order: 0
        },
        {
          id: "snorkling",
          category: "sport",
          title: "Снорклінг",
          emoji: "⏱",
          titleEN: "Snorkling",
          order: 0
        },
        {
          id: "snowboarding",
          category: "sport",
          order: 18,
          emoji: "🏂",
          titleEN: "Snowboarding",
          title: "Сноуборд"
        },
        {
          id: "squash",
          category: "sport",
          title: "Сквош",
          emoji: "💈",
          titleEN: "Squash",
          order: 0
        },
        {
          id: "stretching",
          category: "sport",
          title: "Стретчінг",
          emoji: "🤸",
          titleEN: "Stretching",
          order: 0
        },
        {
          id: "strikeball",
          category: "sport",
          title: "Страйкбол",
          emoji: "🔮",
          titleEN: "Strikeball",
          order: 0
        },
        {
          id: "subboard",
          category: "sport",
          title: "Сабборд",
          emoji: "🚤",
          titleEN: "Subboard",
          order: "0"
        },
        {
          id: "surfing ",
          category: "sport",
          title: "Серфінг",
          emoji: "🏄",
          titleEN: "Surfing",
          order: 0
        },
        {
          id: "swimming",
          category: "sport",
          title: "Плавання",
          emoji: "🏊%E2%80%8D♀%EF%B8%8F",
          titleEN: "Swimming",
          order: 0
        },
        {
          id: "tai_box",
          category: "sport",
          title: "Тайський бокс",
          emoji: "🥊",
          titleEN: "Thai boxing",
          order: 0
        },
        {
          id: "tennis",
          category: "sport",
          title: "Великий теніс",
          order: 8,
          emoji: "🎾",
          titleEN: "Tennis"
        },
        {
          id: "triathlon",
          category: "sport",
          title: "Тріатлон",
          emoji: "⛹️",
          titleEN: "Triathlon",
          order: 0
        },
        {
          id: "uw_hunting",
          category: "sport",
          title: "Підводне полювання",
          emoji: "🤿",
          titleEN: "Underwater hunting",
          order: 0
        },
        {
          id: "uw_swimming",
          category: "sport",
          title: "Підводне плавання",
          emoji: "🥽",
          titleEN: "Underwater swimming",
          order: 0
        },
        {
          id: "volleyball",
          category: "sport",
          title: "Волейбол",
          order: 15,
          emoji: "🏐",
          titleEN: "Volleyball"
        },
        {
          id: "wakeboarding",
          category: "sport",
          title: "Вейкбординг",
          emoji: "🏊",
          titleEN: "Wakeboarding",
          order: 0
        },
        {
          id: "wakesurfing",
          category: "sport",
          title: "Вейксерфінг",
          emoji: "🏄",
          titleEN: "Wakesurfing",
          order: 0
        },
        {
          id: "walking",
          category: "sport",
          title: "Піші прогулянки",
          emoji: "🚶",
          titleEN: "Hiking",
          order: 0
        },
        {
          id: "water_bikes",
          category: "sport",
          title: "Водні мотоцикли",
          emoji: "🚤",
          titleEN: "Water bikes",
          order: 0
        },
        {
          id: "water_skiing",
          category: "sport",
          title: "Водні лижі",
          emoji: "🎿",
          titleEN: "Water skiing",
          order: 0
        },
        {
          id: "weightlifting",
          category: "sport",
          title: "Важка атлетика",
          emoji: "🤸",
          titleEN: "Weightlifting",
          order: 0
        },
        {
          id: "workout",
          category: "sport",
          title: "Воркаут",
          emoji: "⛩",
          titleEN: "Workout",
          order: 0
        },
        {
          id: "wrestling",
          category: "sport",
          title: "Реслінг",
          emoji: "🤼",
          titleEN: "Wrestling",
          order: 0
        },
        {
          id: "yachts",
          category: "sport",
          title: "Яхтінг",
          emoji: "⛵️",
          titleEN: "Yachting",
          order: 0
        },
        {
          id: "yoga",
          category: "sport",
          title: "Йога",
          order: 2,
          emoji: "🧘",
          titleEN: "Yoga"
        },
        {
          id: "ММА",
          category: "sport",
          emoji: "🥊",
          titleEN: "ММА",
          title: "ММА",
          order: 0
        }
      ]
    },
    {
      id: "traveling",
      titleEN: "Traveling",
      title: "Подорожі",
      order: 6,
      passions: [
        {
          id: "active_vacation",
          category: "traveling",
          title: "Активний відпочинок",
          titleEN: "Active leisure",
          emoji: "💪",
          order: 0
        },
        {
          id: "automobiles",
          category: "traveling",
          title: "Автомобілі",
          titleEN: "Automobiles",
          emoji: "🏎",
          order: 0
        },
        {
          id: "autotourism",
          category: "traveling",
          title: "Автотуризм",
          titleEN: "Autotourism",
          emoji: "🚗",
          order: 0
        },
        {
          id: "aviation",
          category: "traveling",
          titleEN: "Aviation",
          emoji: "✈️",
          title: "Авіація",
          order: 0
        },
        {
          id: "beach",
          category: "traveling",
          title: "Пляж",
          titleEN: "Beach",
          emoji: "⛱",
          order: 0
        },
        {
          id: "camping",
          category: "traveling",
          title: "Кемпінг",
          titleEN: "Camping",
          emoji: "🏕",
          order: 0
        },
        {
          id: "camping_in_tents",
          category: "traveling",
          title: "Відпочинок з наметами",
          titleEN: "Camping with tents",
          emoji: "🏕",
          order: 0
        },
        {
          id: "hikes",
          category: "traveling",
          title: "Походи",
          titleEN: "Hiking",
          emoji: "⛺️",
          order: 0
        },
        {
          id: "mountains",
          category: "traveling",
          title: "Гори",
          emoji: "⛰",
          titleEN: "Mountains",
          order: 0
        },
        {
          id: "tourism",
          category: "traveling",
          title: "Пішохідний туризм",
          emoji: "🚶",
          titleEN: "Hiking",
          order: 0
        },
        {
          id: "trekking_to_the_mountains",
          category: "traveling",
          title: "Трекінг у гори",
          emoji: "🚵",
          titleEN: "Trekking to the mountains",
          order: 0
        }
      ]
    },
    {
      id: "pets",
      titleEN: "Pets",
      title: "Домашні тварини",
      order: 7,
      passions: [
        {
          id: "Cats",
          category: "pets",
          emoji: "😺",
          titleEN: "Cats",
          title: "Коти",
          order: 0
        },
        {
          id: "ExoticAnimals",
          category: "pets",
          emoji: "🦜",
          titleEN: "Exotic animals",
          title: "Екзотичні тварини",
          order: 0
        },
        {
          id: "animal_protection",
          category: "pets",
          title: "Захист тварин",
          titleEN: "Animal protection",
          emoji: "🐆",
          order: 0
        },
        {
          id: "dogs",
          category: "pets",
          title: "Собаки",
          titleEN: "Dogs",
          emoji: "🐕",
          order: 0
        },
        {
          id: "pets_caring",
          category: "pets",
          title: "Вигулювання тварин",
          emoji: "🐱",
          titleEN: "Walking the animals",
          order: 0
        }
      ]
    },
    {
      id: "music",
      titleEN: "Music",
      title: "Музика",
      order: 4,
      passions: [
        {
          id: "dj",
          category: "music",
          title: "Діджеїнг",
          titleEN: "DJing",
          emoji: "🎧",
          order: 0
        },
        {
          id: "drums",
          category: "music",
          title: "Барабани",
          titleEN: "Drums",
          emoji: "🥁",
          order: 0
        },
        {
          id: "electro_music",
          category: "music",
          title: "Електронна танцювальна музика",
          titleEN: "EDM",
          emoji: "🎸",
          order: 0
        },
        {
          id: "hytar",
          category: "music",
          title: "Гра на гітарі",
          titleEN: "Guitar performance",
          emoji: "🎸",
          order: 0
        },
        {
          id: "instruments",
          category: "music",
          title: "Музичні інструменти",
          order: 7,
          titleEN: "Musical instruments",
          emoji: "🎷"
        },
        {
          id: "karaoke ",
          category: "music",
          title: "Караоке",
          titleEN: "Karaoke",
          emoji: "🎤",
          order: 0
        },
        {
          id: "music",
          category: "music",
          title: "Музика",
          emoji: "🎼",
          titleEN: "Music",
          order: 0
        },
        {
          id: "singing",
          category: "music",
          title: "Спів",
          emoji: "🎶",
          titleEN: "Singing",
          order: 0
        },
        {
          id: "vinyl",
          category: "music",
          title: "Вінілові пластинки",
          emoji: "📀",
          titleEN: "Vinyl LPs",
          order: 0
        },
        {
          id: "vocal",
          category: "music",
          title: "Вокал",
          order: 5,
          emoji: "🎶",
          titleEN: "Vocals"
        }
      ]
    },
    {
      id: "life",
      title: "Побут",
      order: 3,
      titleEN: "Life",
      passions: [
        {
          id: "Counter_Strike",
          category: "life",
          title: "Counter-Strike",
          titleEN: "Counter-Strike",
          emoji: "🏹",
          order: 0
        },
        {
          id: "Crypto_enthusiast",
          category: "life",
          titleEN: "Crypto enthusiast",
          title: "Криптоентузіаст",
          emoji: "📈",
          order: 0
        },
        {
          id: "Healthcare",
          category: "life",
          emoji: "🏥",
          titleEN: "Healthcare",
          title: "Медицина",
          order: 0
        },
        {
          id: "JapaneseCulture",
          category: "life",
          titleEN: "Japanese culture",
          title: "Японська культура",
          order: 0,
          emoji: "🇯🇵"
        },
        {
          id: "Spa",
          category: "life",
          title: "Spa",
          emoji: "🧖",
          order: 0,
          titleEN: "Spa"
        },
        {
          id: "activism",
          category: "life",
          titleEN: "Activism",
          emoji: "🤵",
          title: "Активіст",
          order: 0
        },
        {
          id: "astrology",
          category: "life",
          title: "Астрологія",
          titleEN: "Astrology",
          emoji: "🌃",
          order: 0
        },
        {
          id: "astronomy",
          category: "life",
          title: "Астрономія",
          titleEN: "Astronomy",
          emoji: "🌃",
          order: 0
        },
        {
          id: "backgammon",
          category: "life",
          title: "Нарди",
          titleEN: "Backgammon",
          emoji: "🎲",
          order: 0
        },
        {
          id: "biohacking",
          category: "life",
          emoji: "🧬",
          titleEN: "Biohacking",
          title: "Біохакінг",
          order: 0
        },
        {
          id: "blogger",
          category: "life",
          emoji: "📸",
          titleEN: "Blogger",
          title: "Блогер",
          order: 0
        },
        {
          id: "boardGames",
          category: "life",
          title: "Настільні ігри",
          order: 2,
          titleEN: "Tabletop games",
          emoji: "♟"
        },
        {
          id: "checkers",
          category: "life",
          title: "Шашки",
          titleEN: "Checkers",
          emoji: "🥌",
          order: 0
        },
        {
          id: "chess",
          category: "life",
          title: "Шахи",
          titleEN: "Chess",
          emoji: "♟",
          order: 0
        },
        {
          id: "cinematograph",
          category: "life",
          title: "Кінематограф",
          titleEN: "Cinematography",
          emoji: "🎬",
          order: 0
        },
        {
          id: "coaching",
          category: "life",
          title: "Коучинг",
          titleEN: "Coaching",
          emoji: "🏫",
          order: 0
        },
        {
          id: "coding",
          category: "life",
          title: "Кодинг",
          titleEN: "Coding",
          order: 0,
          emoji: "👨‍💻"
        },
        {
          id: "community_creating",
          category: "life",
          title: "Будівництво ком'юніті",
          titleEN: "Building the community",
          emoji: "🎤",
          order: 0
        },
        {
          id: "cryptocurrency",
          category: "life",
          title: "Криптовалюта",
          titleEN: "Cryptocurrency",
          emoji: "㊙️",
          order: 0
        },
        {
          id: "dietology",
          category: "life",
          title: "Дієтологія",
          titleEN: "Dietology",
          emoji: "🍚",
          order: 0
        },
        {
          id: "english",
          category: "life",
          title: "Англійська мова",
          titleEN: "English",
          order: 0,
          emoji: "🇬🇧"
        },
        {
          id: "enterprise",
          category: "life",
          title: "Підприємництво",
          titleEN: "Entrepreneurship",
          emoji: "🏢",
          order: 0
        },
        {
          id: "esotericism",
          category: "life",
          title: "Езотерика",
          titleEN: "Esotericism",
          emoji: "✨",
          order: 0
        },
        {
          id: "event_managment",
          category: "life",
          title: "Організатор заходів",
          titleEN: "Event organization",
          emoji: "🏟",
          order: 0
        },
        {
          id: "explorer",
          category: "life",
          title: "Дослідник",
          titleEN: "Exploring",
          emoji: "🏫",
          order: 0
        },
        {
          id: "foreign_lng",
          category: "life",
          emoji: "🌐",
          titleEN: "Foreign languages",
          title: "Іноземні мови",
          order: 0
        },
        {
          id: "int_games",
          category: "life",
          title: "Інтелектуальні ігри",
          titleEN: "Intellectual games",
          emoji: "📊",
          order: 0
        },
        {
          id: "investments",
          category: "life",
          title: "Фінанси та інвестиції",
          titleEN: "Finance and investment",
          emoji: "🏦",
          order: 0
        },
        {
          id: "learning",
          category: "life",
          title: "Навчання",
          titleEN: "Studying",
          emoji: "🎓",
          order: 0
        },
        {
          id: "life_capital",
          category: "life",
          title: "Життєвий капітал",
          titleEN: "Life capital",
          emoji: "🧍",
          order: 0
        },
        {
          id: "lng_learning",
          category: "life",
          title: "Вивчення іноземних мов",
          titleEN: "Learning languages",
          emoji: "🧐",
          order: 0
        },
        {
          id: "mental_health",
          category: "life",
          title: "Ментальне здоров'я",
          titleEN: "Mental health",
          emoji: "👨",
          order: 0
        },
        {
          id: "networking",
          category: "life",
          title: "Нетворкінг",
          titleEN: "Networking",
          order: 0,
          emoji: "👩‍🎤"
        },
        {
          id: "nft",
          category: "life",
          title: "NFT",
          emoji: "💵",
          titleEN: "NFT",
          order: 0
        },
        {
          id: "personal_brend",
          category: "life",
          title: "Особистий бренд",
          emoji: "👨",
          titleEN: "Personal brand",
          order: 0
        },
        {
          id: "podcasts_guest",
          category: "life",
          title: "Гість подкастів",
          emoji: "🎙",
          titleEN: "Guest on podcasts",
          order: 0
        },
        {
          id: "polytics",
          category: "life",
          title: "Політика",
          emoji: "👩",
          titleEN: "Politics",
          order: 0
        },
        {
          id: "profesional_growing",
          category: "life",
          title: "Професійне зростання",
          emoji: "📈",
          titleEN: "Professional growth",
          order: 0
        },
        {
          id: "programming",
          category: "life",
          emoji: "💻",
          titleEN: "Programming",
          title: "Програмування",
          order: 0
        },
        {
          id: "ps",
          category: "life",
          titleEN: "PlayStation",
          title: "Плейстейшн",
          order: 0,
          emoji: "🎮"
        },
        {
          id: "public_performing",
          category: "life",
          title: "Публічні виступи",
          emoji: "🔊",
          titleEN: "Public performing",
          order: 0
        },
        {
          id: "qigong",
          category: "life",
          title: "Ціґун",
          emoji: "🧬",
          titleEN: "Qigong",
          order: 0
        },
        {
          id: "quests",
          category: "life",
          title: "Квести",
          emoji: "📉",
          titleEN: "Quests",
          order: 0
        },
        {
          id: "quizzs",
          category: "life",
          title: "Квізи",
          emoji: "🖌",
          titleEN: "Quizzes.",
          order: 0
        },
        {
          id: "sauna",
          category: "life",
          title: "Лазня",
          emoji: "🧖",
          titleEN: "Sauna",
          order: 0
        },
        {
          id: "sigars",
          category: "life",
          title: "Сигари",
          emoji: "🚬",
          titleEN: "Cigars",
          order: 0
        },
        {
          id: "skincare",
          category: "life",
          title: "Догляд за шкірою",
          emoji: "💅",
          titleEN: "Skincare",
          order: 0
        },
        {
          id: "spiritual_practices",
          category: "life",
          title: "Духовні практики",
          emoji: "🧘",
          titleEN: "Spiritual practices",
          order: 0
        },
        {
          id: "start-up",
          category: "life",
          emoji: "📋",
          title: "Стартапер",
          titleEN: "Start up",
          order: 0
        },
        {
          id: "tableTennis",
          category: "life",
          order: 9,
          emoji: "🏓",
          title: "Настільний теніс",
          titleEN: "Table tennis"
        },
        {
          id: "trenings",
          category: "life",
          title: "Тренінги",
          emoji: "👨‍🏫",
          titleEN: "Trenings",
          order: 0
        },
        {
          id: "videoGames",
          category: "life",
          title: "Відеоігри",
          order: 1,
          emoji: "🎮",
          titleEN: "Videogames"
        }
      ]
    },
    {
      id: "human_values",
      titleEN: "Values and character traits",
      title: "Цінності та риси характеру",
      order: 8,
      passions: [
        {
          id: "Environment",
          category: "human_values",
          emoji: "🌿",
          titleEN: "Environment",
          title: "Довкілля",
          order: 0
        },
        {
          id: "charity",
          category: "human_values",
          title: "Благочинність",
          titleEN: "Charity",
          emoji: "🙏",
          order: 0
        },
        {
          id: "environment",
          category: "human_values",
          title: "Навколишнє середовище",
          titleEN: "Environment",
          emoji: "♻️",
          order: 0
        },
        {
          id: "family",
          category: "human_values",
          title: "Родина",
          titleEN: "Family",
          emoji: "👪",
          order: 0
        },
        {
          id: "feminism",
          category: "human_values",
          title: "Боротьба за права жінок",
          titleEN: "Feminism",
          emoji: "🦸%E2%80%8D♀%EF%B8%8F",
          order: 0
        },
        {
          id: "human_rights",
          category: "human_values",
          title: "Права людини",
          titleEN: "Human rights",
          emoji: "👬",
          order: 0
        },
        {
          id: "lgbt_support",
          category: "human_values",
          title: "Підтримка ЛГБТК",
          titleEN: "LGBTQ+ support",
          emoji: "💑",
          order: 0
        },
        {
          id: "social_projects",
          category: "human_values",
          title: "Соціальні проекти",
          emoji: "🗺",
          titleEN: "Social projects",
          order: 0
        },
        {
          id: "volounteering",
          category: "human_values",
          title: "Волонтерство",
          titleEN: "Volunteering",
          order: 0,
          emoji: "👩‍🚒"
        }
      ]
    },
    {
      id: "food_and_drink",
      titleEN: "Food and drinks",
      title: "Їжа та напої",
      order: 5,
      passions: [
        {
          id: "Matcha",
          category: "food_and_drink",
          emoji: "🍵",
          titleEN: "Matcha",
          title: "Матча",
          order: 0
        },
        {
          id: "baking",
          category: "food_and_drink",
          title: "Випічка",
          order: 6,
          titleEN: "Baking",
          emoji: "🍰"
        },
        {
          id: "coffee",
          category: "food_and_drink",
          title: "Кава",
          titleEN: "Coffee",
          emoji: "☕️",
          order: 0
        },
        {
          id: "cooking",
          category: "food_and_drink",
          title: "Готування",
          order: 5,
          titleEN: "Cooking",
          emoji: "👨‍🍳"
        },
        {
          id: "culinary",
          category: "food_and_drink",
          title: "Кулінарія",
          titleEN: "Culinary",
          emoji: "🔪",
          order: 0
        },
        {
          id: "raw_food",
          category: "food_and_drink",
          title: "Сироїдіння",
          emoji: "🥩",
          titleEN: "Raw foodism",
          order: 0
        },
        {
          id: "sommelier",
          category: "food_and_drink",
          title: "Сомельє",
          emoji: "🍸",
          titleEN: "Sommelier",
          order: 0
        },
        {
          id: "wine",
          category: "food_and_drink",
          title: "Вино",
          emoji: "🍷",
          titleEN: "Wine",
          order: 0
        }
      ]
    },
    {
      id: "creativity",
      title: "Творчість",
      order: 2,
      titleEN: "Creativity",
      passions: [
        {
          id: "Architecture",
          category: "creativity",
          emoji: "🏛️",
          titleEN: "Architecture",
          title: "Архітектура",
          order: 0
        },
        {
          id: "animation",
          category: "creativity",
          title: "Анімація",
          order: 12,
          titleEN: "Animation",
          emoji: "🖼"
        },
        {
          id: "antiques",
          category: "creativity",
          title: "Антикваріат",
          titleEN: "Antiques",
          emoji: "🏺",
          order: 0
        },
        {
          id: "architecture",
          category: "creativity",
          title: "Архітектура",
          titleEN: "Architecture",
          emoji: "🏗",
          order: 0
        },
        {
          id: "arts",
          category: "creativity",
          title: "Мистецтво",
          titleEN: "Arts",
          emoji: "🎨",
          order: 0
        },
        {
          id: "beadwork",
          category: "creativity",
          title: "Бісер",
          titleEN: "Beadwork",
          emoji: "💎",
          order: 0
        },
        {
          id: "blogging",
          category: "creativity",
          titleEN: "Blogging",
          emoji: "📸",
          title: "Блогінг",
          order: 0
        },
        {
          id: "breakdancing",
          category: "creativity",
          title: "Брейк-данс",
          titleEN: "Breakdancing",
          emoji: "🕺",
          order: 0
        },
        {
          id: "burning_man",
          category: "creativity",
          title: "Burning Man",
          titleEN: "Burning Man",
          emoji: "🔥",
          order: 0
        },
        {
          id: "calligraphy",
          category: "creativity",
          title: "Каліграфія",
          order: 14,
          titleEN: "Caligraphy",
          emoji: "🖋"
        },
        {
          id: "ceramics",
          category: "creativity",
          title: "Кераміка",
          order: 24,
          titleEN: "Ceramics",
          emoji: "⚪️"
        },
        {
          id: "collecting",
          category: "creativity",
          title: "Колекціонування",
          order: 3,
          titleEN: "Collecting",
          emoji: "🎡"
        },
        {
          id: "comics",
          category: "creativity",
          title: "Комікси",
          order: 13,
          titleEN: "Comic books",
          emoji: "📰"
        },
        {
          id: "content_creation",
          category: "creativity",
          title: "Творець контенту",
          emoji: "📕",
          titleEN: "Content creator",
          order: 0
        },
        {
          id: "costumes",
          category: "creativity",
          title: "Костюми",
          order: 22,
          titleEN: "Costumes",
          emoji: "🕴"
        },
        {
          id: "dance",
          category: "creativity",
          title: "Танці",
          order: 6,
          titleEN: "Dancing",
          emoji: "🕺"
        },
        {
          id: "decorations",
          category: "creativity",
          title: "Декорації",
          order: 23,
          titleEN: "Decorations",
          emoji: "📔"
        },
        {
          id: "design",
          category: "creativity",
          title: "Дизайн",
          order: 2,
          titleEN: "Design",
          emoji: "🍥"
        },
        {
          id: "drawing",
          category: "creativity",
          title: "Малювання",
          order: 1,
          titleEN: "Drawing",
          emoji: "🖌"
        },
        {
          id: "fashion",
          category: "creativity",
          title: "Мода",
          titleEN: "Fashion",
          emoji: "👠",
          order: 0
        },
        {
          id: "feng_shui",
          category: "creativity",
          title: "Фен Шуй",
          titleEN: "Feng Shui",
          emoji: "🛋",
          order: 0
        },
        {
          id: "florystics",
          category: "creativity",
          title: "Флористика",
          titleEN: "Florystics",
          emoji: "💐",
          order: 0
        },
        {
          id: "fortepiano",
          category: "creativity",
          title: "Фортепіано",
          titleEN: "Fortepiano",
          emoji: "🎹",
          order: 0
        },
        {
          id: "gardening",
          category: "creativity",
          title: "Садівництво",
          order: 4,
          titleEN: "Gardening",
          emoji: "🥀"
        },
        {
          id: "graffiti",
          category: "creativity",
          title: "Графіті",
          order: 20,
          titleEN: "Graffiti",
          emoji: "💅"
        },
        {
          id: "graphics",
          category: "creativity",
          title: "Графіка",
          order: 10,
          titleEN: "Graphics",
          emoji: "🍇"
        },
        {
          id: "handcraft",
          category: "creativity",
          title: "Рукоділля",
          titleEN: "Handcraft",
          emoji: "☝️",
          order: 0
        },
        {
          id: "handmade",
          category: "creativity",
          title: "Хенд-мейд",
          order: 8,
          titleEN: "Hand made",
          emoji: "✋"
        },
        {
          id: "illustration",
          category: "creativity",
          title: "Ілюстрація",
          order: 21,
          titleEN: "Illustration",
          emoji: "📸"
        },
        {
          id: "knitting",
          category: "creativity",
          title: "В'язання",
          titleEN: "Knitting",
          emoji: "🧵",
          order: 0
        },
        {
          id: "literature",
          category: "creativity",
          title: "Поезія та проза",
          titleEN: "Poetry and prose ",
          emoji: "📃",
          order: 0
        },
        {
          id: "mafia",
          category: "creativity",
          title: "Мафія",
          titleEN: "Mafia",
          order: 0,
          emoji: "🀄️"
        },
        {
          id: "magic",
          category: "creativity",
          title: "Магія",
          titleEN: "Magic",
          emoji: "🧙",
          order: 0
        },
        {
          id: "military-tactical_modeling",
          category: "creativity",
          title: "Військово-тактичне моделювання",
          titleEN: "Military tactical simulation",
          emoji: "💣",
          order: 0
        },
        {
          id: "modeling",
          category: "creativity",
          title: "Моделінг",
          emoji: "💄",
          titleEN: "Modeling",
          order: 0
        },
        {
          id: "museums",
          category: "creativity",
          title: "Музеї",
          emoji: "🖼",
          titleEN: "Museums",
          order: 0
        },
        {
          id: "nails",
          category: "creativity",
          title: "Цвяхи",
          emoji: "🔨",
          titleEN: "Nails",
          order: 0
        },
        {
          id: "numismatics",
          category: "creativity",
          title: "Нумізматика",
          emoji: "💰",
          titleEN: "Numismatics",
          order: 0
        },
        {
          id: "painter",
          category: "creativity",
          emoji: "🎨",
          titleEN: "Painter",
          title: "Художник",
          order: 0
        },
        {
          id: "photo",
          category: "creativity",
          emoji: "📷",
          titleEN: "Photo",
          title: "Фотографія",
          order: 0
        },
        {
          id: "photographing",
          category: "creativity",
          emoji: "📷",
          title: "Фотозйомка",
          titleEN: "Photography",
          order: 0
        },
        {
          id: "photography",
          category: "creativity",
          order: 4,
          emoji: "📷",
          title: "Фотографування",
          titleEN: "Photographing"
        },
        {
          id: "podcasts",
          category: "creativity",
          title: "Подкасти",
          order: 19,
          emoji: "🎤",
          titleEN: "Podcasts"
        },
        {
          id: "pole_dance",
          category: "creativity",
          emoji: "🕺",
          titleEN: "Pole-dance",
          title: "Pole-dance",
          order: 0
        },
        {
          id: "radio",
          category: "creativity",
          title: "Радіо",
          order: 18,
          emoji: "🔘",
          titleEN: "Radio"
        },
        {
          id: "sculpture",
          category: "creativity",
          title: "Скульптура",
          order: 9,
          emoji: "🗿",
          titleEN: "Sculpture"
        },
        {
          id: "ship_modeling",
          category: "creativity",
          title: "Моделювання кораблів",
          emoji: "⚓️",
          titleEN: "Ship modeling",
          order: 0
        },
        {
          id: "television",
          category: "creativity",
          title: "Телебачення",
          order: 17,
          emoji: "📺",
          titleEN: "TV"
        },
        {
          id: "theater",
          category: "creativity",
          title: "Театр",
          order: 15,
          emoji: "🎭",
          titleEN: "Theater"
        },
        {
          id: "videoEditing",
          category: "creativity",
          title: "Відеомонтаж",
          order: 11,
          emoji: "📝",
          titleEN: "Video editing"
        },
        {
          id: "videocapturing",
          category: "creativity",
          title: "Відеозйомка",
          emoji: "🎦",
          titleEN: "Videography",
          order: 0
        },
        {
          id: "woodcarving",
          category: "creativity",
          title: "Різьба по дереву",
          emoji: "🌲",
          titleEN: "Woodcarving",
          order: 0
        },
        {
          id: "writing",
          category: "creativity",
          title: "Письмо",
          order: 3,
          emoji: "🖊",
          titleEN: "Writing"
        }
      ]
    },
    {
      id: "books",
      titleEN: "Books",
      title: "Книги",
      order: 9,
      passions: [
        {
          id: "bookworm",
          category: "books",
          emoji: "📚",
          titleEN: "Bookworm",
          title: "Книжковий черв",
          order: 0
        },
        {
          id: "history",
          category: "books",
          title: "Історія",
          titleEN: "History",
          emoji: "✏️",
          order: 0
        },
        {
          id: "neurobiology ",
          category: "books",
          title: "Нейробіологія",
          emoji: "⚗️",
          titleEN: "Neurobiology",
          order: 0
        },
        {
          id: "neurophysiology",
          category: "books",
          title: "Нейрофізіологія",
          emoji: "🧪",
          titleEN: "Neurophysiology",
          order: 0
        },
        {
          id: "neuropsychology ",
          category: "books",
          title: "Нейропсихологія",
          emoji: "🥼",
          titleEN: "Neuropcychology",
          order: 0
        },
        {
          id: "philosophy ",
          category: "books",
          title: "Філософія",
          emoji: "📚",
          titleEN: "Philosophy",
          order: 0
        },
        {
          id: "psychology ",
          category: "books",
          title: "Психологія",
          emoji: "↖️",
          titleEN: "Psychology",
          order: 0
        },
        {
          id: "reading",
          category: "books",
          title: "Читання",
          emoji: "📚",
          titleEN: "Reading",
          order: 0
        },
        {
          id: "self-development",
          category: "books",
          title: "Саморозвиток",
          emoji: "📚",
          titleEN: "Self-development",
          order: 0
        }
      ]
    },
  ],
  isNextActive: !!localStorageArray ? !!localStorageArray.length : false,
  selectedItems: localStorageArray || [],

}

const step3Reducer = (state = initialState, action) => {

  switch (action.type) {

    case SELECT_ELEMENT:
      const { selectedItems } = state
      const array =  selectedItems.includes(action.id)
      ? [...selectedItems.filter(id => action.id !== id)]
      : [...selectedItems, action.id]
      return {
        ...state,
        isNextActive: !!array.length,
        selectedItems: [...array]
      }

    default:
      return state
  }
}

export default step3Reducer;

//Action Creators
export const step3SelectItem = (id) => ({ type: SELECT_ELEMENT, id })


