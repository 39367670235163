import React from "react"
import { useNavigate } from 'react-router-dom'

//styles
import classes from './Next.module.css'

const Next = ({ url, currentStep, isActive, blur = true, getUsersCount = () => { }, children }) => {
  const navigate = useNavigate()

  const onSubmit = () => {
    getUsersCount()
    navigate(url)

  }

  return (
    <div className={classes.nextFlex}>

      {blur && <div className={classes.blur}></div>}

      {
        currentStep &&
        <div className={classes.currentStep}>{currentStep}/3</div>
      }

      <button className={`${classes.buttonStyle} ${isActive ? classes.next : classes.text}`}
        onClick={onSubmit} disabled={!isActive}>
        {children} </button>

    </div>
  )
}

export default Next
