//styles
import classes from "./MainPage.module.css"

const Feedback = ({ name, date, text }) => {
    return (
        <div className={classes.feedback}>
            <div className={classes.feedbackHeader}>
                <div className={classes.feedbackName}>{name}</div>
                <div className={classes.feedbackDate}>{date}</div>
            </div>
            <div className={classes.feedbackText}>{text}</div>
        </div>
    )
}

export default Feedback